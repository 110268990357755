<template>
  <header class="border-b-sm border-blue-400 bg-blue-50">
    <div class="max-w-content p-xxs mx-auto h-full">
      <NuxtLink :to="{ name: 'index' }">
        <img
          class="h-full"
          src="~/assets/images/logos/reseau-agenda.svg"
          alt="Retour à l'accueil de Agenda.org, le réseau des agendas"
        />
      </NuxtLink>
    </div>
  </header>
</template>
